nav.main-navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: var(--navbar-height);
  padding: 0 5%;

  * {
    color: #fff;
    font-family: var(--font-title);
  }

  .home-logo {
    img {
      width: 64px;
      height: 64px;

      @media screen and (max-width: 764px) {
        width: 45px;
        height: 45px;
      }
    }
  }

  .center-navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    @media screen and (max-width: 764px) {
      display: none;
    }

    li {
      list-style: none;

      a {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px 16px;
        border-radius: 5px;
        text-decoration: none;

        svg {
          opacity: 0.4;
        }
      }

      &.selected {
        a {
          background-color: var(--clr-dark-surface);
          color: #000;
        }
      }
    }
  }

  .user-portal-btn {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    @media screen and (max-width: 764px) {
      .user-name {
        display: none;
      }
    }

    img {
      width: 48px;
      height: 48px;
      border-radius: 24px;
    }

    path {
      fill: #fff;
    }
  }

  .user-portal {
    position: absolute;
    padding: 16px;
    min-width: 140px;
    right: 0;
    top: calc(100% + 20px);
    box-shadow: 0px 14px 30px 0px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    border-radius: 8px;
    cursor: default !important;
    z-index: 300;
    display: flex;
    flex-direction: column;
    gap: 10px;

    * {
      color: var(--clr-text);
    }

    a,
    span {
      text-decoration: none;
      cursor: pointer;
    }

    span:hover {
      color: var(--clr-error);
    }
  }
}
