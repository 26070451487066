.device-planning {
  padding: 20px;
  max-width: 100%;

  * {
    -webkit-user-select: none;
    user-select: none;
  }

  .planning-container {
    width: 100%;
    overflow-x: scroll;
    padding-bottom: 20px;

    .dropout-title {
      margin-top: 30px;
      display: block;
      font-weight: bold;
      font-size: 1rem;
    }
  }

  .dropout-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  table {
    --table-border-radius: 4px;
    --table-selected-border: 4px solid #000;

    border-collapse: collapse;
    margin-top: 20px;

    thead {
      tr {
        th {
          color: #000;

          &.corner {
            background-color: #000;
            border-top-left-radius: var(--table-border-radius);

            @media screen and (max-width: 764px) {
              position: sticky;
              left: -20px;
            }
          }

          .hour {
            border-style: solid;
            border-color: var(--clr-divider);
            border-width: 1px;
            border-left-width: 0;
          }

          &:last-child {
            .hour {
              border-top-right-radius: var(--table-border-radius);
              border-bottom-right-radius: var(--table-border-radius);
            }
          }

          &.sec:first-child {
            .hour {
              border-top-left-radius: var(--table-border-radius);
              border-bottom-left-radius: var(--table-border-radius);
              border-left-width: 1px;
            }
          }
        }
      }
    }

    tbody {
      tr {
        &:last-child {
          td:first-child {
            .day {
              border-bottom-left-radius: var(--table-border-radius);
              border-bottom-right-radius: var(--table-border-radius);
            }
          }
        }

        td {
          padding: 2px 0;

          &.no-padding {
            padding: 0 !important;

            background-color: #fff;
            @media screen and (max-width: 764px) {
              position: sticky;
              left: -20px;
            }
          }

          .day {
            display: flex;
            align-items: center;
            justify-content: center;
            border-style: solid;
            border-color: var(--clr-divider);
            border-width: 1px;
            border-top-width: 0;
            padding: 4px 8px;
            height: 36px;
          }

          .heat-selector {
            width: 52px;
            height: 32px;

            .color-item {
              height: 32px;
              width: 100%;

              &.heat-0 {
                background-color: #479cff;
              }

              &.heat-1 {
                background-color: #f4b536;
              }

              &.heat-2 {
                background-color: #e97000;
              }

              &.heat-3 {
                background-color: #f43636;
              }

              &.dropout-on {
                background-color: #c4c4c4;
              }

              &.dropout-off {
                background-color: #f0f0f0;
              }

              &.selected {
                border-top: var(--table-selected-border);
                border-bottom: var(--table-selected-border);
              }
            }

            &.dropout {
              height: 18px;
              .color-item {
                height: 18px;
              }
            }

            &.right {
              padding-right: 2px;
              .color-item {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;

                &.selected {
                  border-right: var(--table-selected-border);
                }
              }
            }

            &.left {
              padding-left: 2px;
              .color-item {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;

                &.selected {
                  border-left: var(--table-selected-border);
                }
              }
            }
          }
        }
      }
    }
  }

  .planning-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    gap: 20px;

    .legend {
      display: flex;
      align-items: center;
      gap: 40px;
      padding-left: 20px;

      span {
        display: flex;
        align-items: center;
        position: relative;

        &::before {
          content: "";
          display: block;
          width: 12px;
          height: 12px;
          background-color: red;
          position: absolute;
          left: -20px;
          border-radius: 4px;
        }

        &.heat-0::before {
          background-color: #479cff;
        }

        &.heat-1::before {
          background-color: #f4b536;
        }

        &.heat-2::before {
          background-color: #e97000;
        }

        &.heat-3::before {
          background-color: #f43636;
        }
      }
    }

    @media screen and (max-width: 764px) {
      flex-direction: column;
      align-items: flex-start;

      .legend {
        flex-direction: column;
        gap: 5px;
        align-items: flex-start;
      }
    }

    .btn-container {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
  }
}
