.auth-page {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100svh;
  padding: 30px 0;
  align-items: center;

  section {
    > .container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      max-width: 1200px;
    }
  }

  .form-container {
    width: calc(50% - 50px);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media screen and (max-width: 764px) {
      width: 100%;
    }

    .go-back-btn {
      display: flex;
      align-items: center;
      gap: 2px;
      margin-bottom: 20px;

      * {
        font-size: 16px;
        color: #667a7c;
      }
    }

    h1 {
      color: var(--clr-strong-text);
      font-weight: bold;

      span {
        color: var(--clr-primary);
      }
    }

    .main-description {
      margin-top: 20px;
      margin-bottom: 40px;
    }

    .primary-link {
      color: var(--clr-primary);
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 40px;

      width: 100%;

      .row {
        margin-bottom: 20px;
        width: 100%;
      }

      .input-row {
        gap: 20px;
        width: 100%;
        margin-bottom: 0;

        .input-block {
          width: 100%;
        }
      }

      label:not(.ant-checkbox-wrapper) {
        margin-bottom: 5px;
        display: block;
        color: var(--clr-strong-text);
      }

      > input,
      .input-row input,
      > .ant-input-affix-wrapper {
        padding: 8px 14px;
        width: 100%;
        margin-bottom: 20px;
        border-radius: 8px;
      }
    }

    .success-icon {
      width: 50px;
      height: 50px;
      margin-bottom: 20px;
    }
  }
}
