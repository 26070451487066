.main-btn {
  border-radius: 6px;
  padding: 10px 16px;
  transition: 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  z-index: 10;

  .btn-title {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  svg {
    transition: 0.3s;
  }

  @media screen and (max-width: 764px) {
    padding: 8px 12px;
  }

  &.color-primary {
    background-color: var(--clr-dark-primary);
    box-shadow: 2px 10px 34px 10px rgba(1, 33, 36, 0.12);
    span {
      color: #fff;
    }

    &:hover {
      background-color: var(--clr-dark-primary-hover);
    }

    &.disabled {
      background-color: var(--clr-deactivate);
      box-shadow: none;
      cursor: default;

      span {
        color: #fff;
      }
    }
  }

  &.color-secondary {
    background-color: var(--clr-secondary-60);
    span {
      color: #fff;
    }

    &:hover {
      background-color: var(--clr-secondary);
    }

    &.disabled {
      background-color: var(--clr-deactivate);
      box-shadow: none;
      cursor: default;

      span {
        color: #fff;
      }
    }
  }

  &.color-on-primary {
    background-color: #fff;
    span {
      color: var(--clr-dark-primary);
    }

    svg {
      fill: var(--clr-dark-primary);
    }

    &:hover {
      background-color: rgba($color: #fff, $alpha: 0.9);
    }

    &.disabled {
      background-color: rgba($color: #fff, $alpha: 0.5);
      box-shadow: none;
      cursor: default;
    }
  }

  &:hover {
    svg {
      transform: translateX(3px);
    }
  }

  .loader {
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-left-color: transparent;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 1s linear infinite;
  }

  span {
    font-size: 16px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
