#home-view {
  .homes-container {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 40px;
    align-items: stretch;
  }

  .empty-home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 200px;
    height: 200px;
    background-color: var(--clr-grey-surface);
    padding: 30px;
    border-radius: 20px;
    gap: 20px;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      opacity: 0.8;
    }

    svg {
      width: 36px;
      height: 36px;
    }
  }
}
