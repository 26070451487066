@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap&family=Lato");

:root {
  --clr-background: #fff;
  --clr-dark-primary: #012124;
  --clr-dark-primary-hover: #213d3f;
  --clr-surface: #fff;
  --clr-grey-surface: #e8ebeb;
  --clr-dark-surface: #344d50;
  --clr-text: rgba(0, 0, 0, 0.8);
  --clr-strong-text: #012124;
  --clr-secondary-text: #566573;
  --clr-primary: #f7aa3f;
  --clr-secondary: #82ba71;
  --clr-error: #f1948a;
  --clr-warning: #f7dc6f;
  --clr-success: #82e0aa;
  --clr-on-primary: #fff;
  --border-radius: 6px;
  --clr-divider: #d5dbdb;
  --clr-divider-light: #d5dbdb;
  --clr-deactivate: #d5dbdb;
  --clr-deactivate-light: #d5dbdb;
  --box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  --clr-navbar-selected: rgba(41, 128, 185, 0.2);
  --clr-lst-navbar-selected: rgba(0, 0, 0, 0.05);
  --clr-header-background: rgba(255, 255, 255, 0.5);
  --clr-navbar-hover: rgba(0, 0, 0, 0.1);

  --clr-secondary-60: rgba(130, 186, 113, 0.6);
  --clr-secondary-30: rgba(130, 186, 113, 0.3);

  --font-title: "Poppins", "Arial Narrow", Arial, sans-serif;
  --font-text: "Lato", "Arial Narrow", Arial, sans-serif;

  --navbar-height: 100px;

  @media screen and (max-width: 764px) {
    --navbar-height: 45px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--font-text);
}

h1,
h2,
h3,
h4,
h5,
h6,
.title {
  font-family: var(--font-title);

  * {
    font-family: var(--font-title);
  }
}

body {
  width: 100vw;
  overflow-x: hidden;
}

.main-container {
  width: 100%;
  height: 100svh;
  background-color: var(--clr-dark-primary);
  padding: 20px 0 0 0;

  .main-content {
    padding: 40px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    margin-top: 20px;
    height: calc(100% - var(--navbar-height) - 20px);
    overflow-y: auto;
    background-color: var(--clr-background);
  }

  @media screen and (max-width: 764px) {
    padding: 10px 0 0 0;
    .main-content {
      padding: 40px 20px;
      margin-top: 10px;
    }
  }
}

.main-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 5%;

    .container {
      width: 100%;
      max-width: 1450px;
    }
  }
}

.arrow-hover-move {
  transition: 0.3s;
  cursor: pointer;
  * {
    transition: 0.3s;
  }

  .arrow {
    transition: 0.3s;
  }

  &:hover {
    color: #000 !important;

    * {
      color: #000 !important;
    }

    .arrow {
      transform: translateX(-2px);
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.classic-form {
  display: flex;
  gap: 20px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1200px;

  .input-side {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 50%;
    max-width: 500px;
  }

  .row {
    align-items: flex-end;
    width: 100%;
    gap: 20px;
  }

  .input-block {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    input {
      padding: 8px 14px;
    }
  }
}

.soliseco-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 764px) {
    align-items: flex-end;
  }

  .modal-center {
    padding: 40px;
    background-color: var(--clr-background);
    border-radius: var(--border-radius);
    position: relative;

    @media screen and (max-width: 764px) {
      border-radius: 20px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .modal-header {
      display: flex;
      flex-direction: column;

      .close-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 15px;
        right: 15px;
        width: 30px;
        height: 30px;
        background-color: rgba($color: #000000, $alpha: 0.3);
        border-radius: 50%;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          box-shadow: var(--box-shadow);
        }

        svg {
          color: #fff;
          font-size: 14px;
        }
      }

      span {
        font-weight: bold;
        font-size: 1.2rem;
      }

      p {
        max-width: 500px;
      }
    }

    .modal-content {
      padding: 30px 0;
      .classic-form {
        flex-direction: column;
      }
    }

    .modal-footer {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
    }
  }
}

.ant-select-dropdown {
  z-index: 10000;
}

input {
  @media screen and (max-width: 764px) {
    font-size: 16px !important;
  }
}
