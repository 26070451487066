#settings-view {
  .settings-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    border: 1px solid var(--clr-divider);
    border-radius: 20px;
    width: 100%;
    margin-top: 50px;
    padding: 10px;

    .empty-item {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 200px;
      width: 100%;

      span {
        font-weight: bold;
        font-size: 1.2rem;
      }
    }

    @media screen and (max-width: 764px) {
      flex-direction: column;
    }
  }
}
