.device-home-settings {
  width: 100%;

  @media screen and (max-width: 764px) {
    display: flex;
    flex-direction: column-reverse;
  }

  .save-header {
    padding: 30px;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    position: sticky;
    z-index: 5;
    background-color: rgba($color: #fff, $alpha: 0.3);
    backdrop-filter: blur(4px);
    top: 10px;

    @media screen and (max-width: 764px) {
      bottom: -40px;
    }
  }

  .device-home-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    padding: 10px 20px;

    .section-warning {
      display: flex;
      color: #f7aa3f
    }
  
    .settings-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 20px;
      width: 100%;
      padding-bottom: 30px;
      > span {
        font-weight: bold;
        font-size: 1.1rem;
      }

      .row {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }

    .setting-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      background-color: #f8f8f8;
      border-radius: 10px;
      padding: 12px 18px;
      gap: 20px;

      .input-container {
        display: flex;
        align-items: center;
        gap: 20px;

        > *:not(.unit):not(button) {
          min-width: 200px;

          @media screen and (max-width: 764px) {
            min-width: 120px;
          }
        }
      }
    }

    .delete-container {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 40px;
    }
  }
}
