.planning-modal {
  .modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    .temp-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 40px;
      max-width: 320px;

      .temp-block {
        width: 140px;
        height: 140px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        box-shadow: var(--box-shadow);
        transition: 0.3s;
        text-align: center;

        @media screen and (max-width: 764px) {
          position: sticky;
          width: calc(50% - 20px);
          height: 100px;
        }

        &.selected {
          border: 2px solid #000;
          box-shadow: unset;
        }

        span {
          font-weight: bold;
          font-size: 18px;
          color: #fff;
          

        }

        .temp-range {
          margin-top: 4px;
          font-size: 12px;
        }
        &.heat-0 {
          background-color: #479cff;
        }

        &.heat-1 {
          background-color: #f4b536;
        }

        &.heat-2 {
          background-color: #e97000;
        }

        &.heat-3 {
          background-color: #f43636;
        }
      }
    }
  }
}
