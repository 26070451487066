.device-dashboard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 40px;
  gap: 20px;
  width: 100%;

  @media screen and (max-width: 764px) {
    padding: 0;
  }

  .row {
    display: flex;
    width: 100%;
    align-items: stretch;
    justify-content: space-between;
    gap: 20px;

    @media screen and (max-width: 1000px) {
      flex-direction: column;
    }
  }

  .pie-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid var(--clr-divider);
    border-radius: 20px;
    padding: 40px;
    width: 100%;

    @media screen and (max-width: 700px) {
      padding: 40px 0;
    }

    .pies {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      gap: 10px;
    }

    .pie-block {
      display: flex;
      flex-direction: column;
      width: 340px;
      max-width: 100%;

      .pie-title {
        padding: 0 20px;
        svg {
          margin-left: 10px;
        }
      }

      .chart-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 340px;
        height: 320px;
        max-width: 100%;
        position: relative;

        .absolute-center-title {
          position: absolute;
          display: flex;
          flex-direction: column;
          max-width: 80px;

          text-align: center;

          span {
            &.title {
              font-size: 14px;
            }
            font-size: 12px;
          }
        }

        * {
          outline: none;
        }
      }

      @media screen and (max-width: 930px) {
        width: 100%;
        .chart-container {
          width: 100%;
        }
      }

      .legend-container {
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 0 20px;

        .legend-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;

          .color-block {
            display: block;
            width: 12px;
            height: 12px;
            margin-right: 10px;
          }

          span:not(.color-block) {
            font-size: 14px;
            line-height: 16px;
            max-width: calc(100% - 30px);
          }
        }
      }
    }
  }

  .chart-title-block {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 40%;

    &.large {
      width: 60%;
    }

    .title {
      margin-bottom: 10px;
      margin-top: 20px;
      svg {
        margin-left: 10px;
      }
    }

    .chart-block {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-height: 500px;
      padding: 20px;
      border: 1px solid var(--clr-divider);
      border-radius: 20px;

      .line-chart-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-block-end: 8px;
    
       @media screen and (max-width: 700px) {
          flex-direction: column;
          .ant-switch {
            margin-top: 14px;
          }
        }
      }
    
      .tooltip-container {
        background-color: rgba(255, 255, 255, 0.6);
        padding: 10px;
        border-radius: 10px;
        box-shadow: var(--box-shadow);
      }

      .line-skeleton {
        width: 100%;
        height: 100%;

        span {
          width: 100%;
          border-radius: 20px;
          height: 100%;
        }
      }

      &.center {
        align-items: center;
      }

      &.data {
        height: 100%;
        padding: 30px;
        width: 100%;
        gap: 12px;

        &.empty {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .data-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          .label {
            font-weight: 300;
          }

          .value {
            text-wrap: nowrap;
            color: #82ba71;

            &.cell {
              background-color: #e0eedb;
              padding: 2px 4px;
              border-radius: 4px;
            }
          }
        }
      }
    }

    @media screen and (max-width: 1000px) {
      width: 100%;
      &.large {
        width: 100%;

        .chart-block {
          height: 600px;
          padding: 20px 0;
        }
      }
    }
  }
}
