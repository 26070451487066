.home-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 30px;
  border-radius: 50px;
  position: relative;
  border: 1px solid var(--clr-divider);
  min-width: 200px;
  gap: 30px;
  align-items: stretch;

  @media screen and (max-width: 764px) {
    flex-wrap: wrap;
    width: 100%;
  }

  .home-title {
    background-color: var(--clr-background);
    position: absolute;
    height: 32px;
    top: -16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
  }

  .device-item {
    display: flex;
    flex-direction: column;
    background-color: var(--clr-dark-primary);
    padding: 20px;
    border-radius: 20px;
    min-width: 220px;

    @media screen and (max-width: 555px) {
      width: 100%;
    }

    * {
      color: #fff;
    }

    .device-header {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      min-width: 140px;

      padding: 0;

      .temperature-status {
        padding: 4px 10px;
        border-radius: 20px;
      }

      .settings-container {
        cursor: pointer;

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    .device-center {
      display: flex;
      flex-direction: column;
      padding: 20px 0;

      .device-name-status {
        font-size: 1.2rem;
        span {
          font-size: 0.8rem;
          color: var(--clr-success);
        }
      }
    }
  }

  .btn-container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media screen and (max-width: 764px) {
      flex-direction: row;
    }

    .btn {
      background-color: var(--clr-grey-surface);
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      cursor: pointer;
      transition: 0.3s;

      svg {
        width: 30px;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .empty-device {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 200px;
    background-color: var(--clr-grey-surface);
    padding: 30px;
    border-radius: 20px;
    gap: 20px;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      opacity: 0.8;
    }

    svg {
      width: 36px;
      height: 36px;
    }
  }
}
