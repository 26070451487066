.graph-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  padding: 40px 0 20px 0;

  .graph-skeleton {
    span {
      width: 180px !important;
      height: 180px !important;
      border-radius: 20px;
      display: block;
    }
  }

  .legend {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 30px;
    gap: 5px;

    .legend-skeleton {
      span {
        width: 80%;
        height: 16px;
      }
    }
  }
}
