.auth-decorator {
  background-color: var(--clr-dark-primary);
  width: calc(50% - 50px);
  height: 80svh;
  max-height: 800px;
  border-radius: 60px;
  position: relative;

  @media screen and (max-width: 764px) {
    display: none;
  }

  .title-container {
    display: flex;
    flex-direction: column;
    z-index: 5;
    position: relative;
    align-items: flex-start;
    padding: 40px;

    .logo-container {
      background-color: rgba($color: #fff, $alpha: 0.2);
      border-radius: 15px;
      padding: 8px;
      margin-bottom: 20px;

      img {
        width: 40px;
        height: 40px;
      }
    }

    h2 {
      color: #fff;
      max-width: 500px;
    }

    p {
      color: rgba(255, 255, 255, 0.8);
      max-width: 400px;
    }
  }

  .absolute-rights {
    z-index: 5;
    position: absolute;
    color: #fff;
    bottom: 40px;
    right: 40px;
  }

  .decorator-block {
    z-index: 1;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    .bottom-right-lines {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 40%;
    }

    .decoration-block {
      background-color: rgba(255, 255, 255, 0.2);
      display: block;
      border-radius: 20px;
      position: absolute;

      &.b-left {
        width: 100%;
        height: 50%;
        bottom: -20px;
        right: 50%;
      }

      &.t-right {
        height: 100%;
        width: 200px;
        bottom: 60%;
        right: 40px;
      }
    }
  }
}

.auth-top-left-lines {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  max-width: 100vw;
}
