.settings-aside {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #f8f8f8;
  padding: 30px 15px;
  gap: 10px;
  position: sticky;
  top: 10px;
  min-width: 320px;

  @media screen and (max-width: 900px) {
    min-width: 220px;
  }

  @media screen and (max-width: 764px) {
    position: relative;
    width: 100%;
    background-color: transparent;
    padding: 10px;
    margin-bottom: 40px;
  }

  .settings-aside-home-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    transition: 3s;

    background-color: #f4f5f6;
    border-radius: 10px;

    .home-block-header {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      background-color: #eeeff0;
      border-radius: 10px;
      padding: 10px;
      cursor: pointer;

      &:hover {
        color: var(--clr-secondary-60);
        .row {
          svg,
          svg * {
            fill: var(--clr-secondary-60);
          }
        }

        .chevron * {
          stroke: var(--clr-secondary-60);
        }
      }

      &.selected {
        color: var(--clr-secondary);
        font-weight: 900;
        .row {
          svg,
          svg * {
            fill: var(--clr-secondary);
          }
        }

        .chevron * {
          stroke: var(--clr-secondary);
        }
      }

      > div {
        gap: 10px;
      }
    }

    .home-block-content {
      display: flex;
      flex-direction: column;
      padding: 15px 10px 15px 25px;
      gap: 10px;

      .device-block {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;

        .device-icon-name {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;

          svg {
            width: 16px;
            height: 16px;
          }
        }

        &:hover {
          color: var(--clr-secondary-60);

          .device-icon-name {
            svg,
            svg * {
              fill: var(--clr-secondary-60);
            }
          }

          .chevron * {
            stroke: var(--clr-secondary-60);
          }
        }

        &.selected {
          color: var(--clr-secondary);
          font-weight: 900;

          .device-icon-name {
            svg,
            svg * {
              fill: var(--clr-secondary);
            }
          }

          .chevron * {
            stroke: var(--clr-secondary);
          }
        }
      }
    }
  }
}
