.default-view-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  .row {
    align-items: flex-end;
    gap: 30px;

    @media screen and (max-width: 764px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .new-device-btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      border: 1px solid var(--clr-divider);
      padding: 8px 12px;
      border-radius: 10px;
      cursor: pointer;

      svg {
        width: 20px;
        height: 20px;
      }

      span {
        font-size: 14px;
      }
    }
  }

  .view-title-container {
    h2 {
      &.big-title {
        font-size: 2rem;

        span {
          color: var(--clr-secondary);
        }
      }
    }

    p {
      max-width: 500px;
      margin-top: 10px;
    }
  }

  .date-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 20px;
    border-right: 5px solid var(--clr-secondary-30);

    @media screen and (max-width: 970px) {
      display: none;
    }

    .weekday {
      font-size: 32px;
      font-weight: 500;
      text-transform: capitalize;
      color: var(--clr-strong-text);
    }

    .day-month {
      font-size: 32px;
      font-weight: 600;
      color: var(--clr-secondary-60);

      span {
        color: var(--clr-secondary);
      }
    }
  }
}
