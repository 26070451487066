.device-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--clr-divider);
  border-radius: 20px;
  width: 100%;
  margin-top: 50px;

  @media screen and (max-width: 764px) {
    border: none;
  }
}
