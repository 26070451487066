.account-main-view {
  .center-view {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
    border: 1px solid var(--clr-divider);
    border-radius: 20px;
    width: 100%;
    padding: 40px 100px;
    margin-top: 50px;

    @media screen and (max-width: 764px) {
      padding: 0;
      border: none;
      .center-view-form {
        flex-direction: column;

        .input-side {
          width: 100%;
        }
      }
    }

    .center-view-form-submit {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 10px;
      padding-top: 20px;
    }
  }
}
