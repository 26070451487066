.device-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  .header-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .block-title {
      font-size: 18px;
    }

    .header-block-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;

      .button-container {
        border-radius: 10px;
        background: #e8ebeb;
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      .ant-select {
        min-width: 120px;
        height: 50px;
        .ant-select-selector {
          background-color: var(--clr-dark-primary);
          color: #fff;
          outline: none;
          border: none;
        }

        .ant-select-arrow {
          color: #fff;
        }

        &.ant-select-open {
          .ant-select-selector {
            .ant-select-selection-item {
              color: rgba($color: #fff, $alpha: 0.7);
            }
          }
          .ant-select-arrow {
            color: rgba($color: #fff, $alpha: 0.7);
          }
        }
      }

      .toggle-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 24px;
        border-radius: 10px;
        cursor: pointer;
        transition: 0.3s;
        height: 50px;

        &.fill svg {
          fill: var(--clr-dark-primary);
          * {
            fill: var(--clr-dark-primary);
          }
        }

        &.stroke svg {
          stroke: var(--clr-dark-primary);

          * {
            stroke: var(--clr-dark-primary);
          }
        }

        &.selected {
          background-color: var(--clr-dark-primary);

          span {
            color: #fff;
          }
        }
      }

      &.controle {
        gap: 30px;
        border-radius: 10px;
        border: 1px solid rgba(1, 33, 36, 0.1);
        background-color: rgba(1, 33, 36, 0.05);
        padding: 5px;

        .toggle-btn {
          padding: 5px;
          width: 40px;
          height: 40px;
          position: relative;

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;

          svg {
            width: 18px;
            height: 18px;
          }

          span {
            font-size: 8px;
            position: absolute;
            bottom: 5px;
          }

          &.selected {
            &.fill svg {
              fill: #fff;
              * {
                fill: #fff;
              }
            }

            &.stroke svg {
              stroke: #fff;

              * {
                stroke: #fff;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 764px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .header-block {
      width: 100%;
      align-items: flex-start;

      &.fixed {
        .controle {
          background-color: #f2f4f4;
        }
      }

      @media screen and (max-width: 400px) {
        .header-block-content:not(.controle) {
          width: 100%;
          justify-content: space-between;
        }
      }
    }
  }
}
