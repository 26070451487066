.time-selector {
  background-color: rgb(245, 245, 245);
  border-radius: 6px;
  border: 1px solid rgb(200, 200, 200);
  padding: 2px;
  margin-top: 40px;

  * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .upper-view {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;

    .anticon:not(.disabled) svg {
      transition: 0.3s;
      cursor: pointer;

      &:hover {
        transform: scale(1.2);
      }
    }

    .anticon.disabled svg {
      opacity: 0.3;
      cursor: default;
    }
  }

  .bottom-view {
    .ant-segmented {
      background-color: transparent;
      .ant-segmented-group {
        background-color: transparent;
      }
    }
  }

  @media screen and (max-width: 700px) {
    width: calc(100% - 40px);
    max-width: 450px;

    .bottom-view {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      overflow-x: auto;
    }
  }
}
